
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    CreateUploadDialog,
  },
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    upload360Object: undefined,
    upload360Dialog: false,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore, useProjectStore, useAccountStore),
  },
  methods: {
    on360UplaodDialogOpen() {
      const uploadStats = {
        percentage: 0,
        totalSize: 0,
        uploadedSize: 0,
        isUploading: false,
        isProcessing: false,
        uploadFinished: false,
        uploadType: INGEST_UPLOADS_TYPES._360Assets,
      }
      this.ingestFileUploaderStore.currentUploadStats = uploadStats
      this.ingestFileUploaderStore.uploadStats.push(uploadStats)
      this.upload360Dialog = true
    },
    on360UplaodDialogClosed() {
      this.ingestFileUploaderStore.currentBIMUploadStat = undefined
      this.upload360Dialog = false
      this.upload360Object = undefined
    },
  },
}
